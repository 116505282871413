import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import scrollTo from 'gatsby-plugin-smoothscroll';
export default function Secseven(){
    return (
        <>

           <Container fluid className="__bg-primary-sl-mirror">
                <Container className="fade-in">
                    <Row className="justify-content-center">
                        <h2 className="__text-headline text-white text-center  __margin-bottom-5-wl">
                        Lihat, dengar dan rasakan testimoni jujur dari alumni online training sebelumnya..
                        </h2>
                    </Row>
                    <Row>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Seneng banget setelah mengikuti kelas Attraction Mastery kemarin mindsetku tentang romansa jadi terbuka selama ini banyak mitos-mitos yang dijelaskan waktu kelas berlangsung kemarin yg aku percaya dan secara gak sadar selama ini yang bikin kehidupan romansaku selalu gagal karena mindsetku tentang romansa yang salah, sekarang setelah ilmu yang diberikan coach Jose kemarin saya jadi tau apa yg harus saya perbaiki dalam kehidupan romansa saya. Terima Kasih coach” </p>
                                    <p className="__text-testimoni"><b>- Nanda</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Setelah ikut Attraction Mastery yang seru kemarin, saya jd lebih paham teori tentang cara membangun relasi. Karena selama ini saya berdasarkan insting saja. Banyak juga yang ternyata kurang tepat. Banyak bgt hal yang didapat, pelan pelan lagi saya coba praktekan supaya sy bisa membangun hubungan yg lebih baik dari sebelumnya. Thanks coach Jose and Team” </p>
                                    <p className="__text-testimoni"><b> - Maria</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Baru pertama kalinya saya ikut kelas Attraction Mastery awalnya saya ragu untuk mengikutinya, tetapi setelah saya ikut kelasnya ternyata asik, seru dan banyak hal yang saya belum tahu jadi tersalurkan ilmunya oleh coach Jose sangat bagus dijadikan pelajaran yang positif untuk diambil.” </p>
                                    <p className="__text-testimoni"><b>  - Riftha</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Saya bisa dengan mudah mengenal kekurangan dan kesalahan saya dalam kehidupan romansa yang selama ini membuat saya banyak gagal. Mindset saya pun lebih terbuka tentang kehidupan romansa saya ke depannya”  </p>
                                    <p className="__text-testimoni"><b>- Ratih</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Manfaatnya saya mengetahui cara menumbuhkan benih cinta ke orang yang kita sayang, mulai belajar menumbuhkan ketertarikan dari tahap per tahap. Dan mulai tahu saat kita mendekati seseorang, hendaknya menggunakan pdkt emosi, bukan logika. Itu terasa sangat elegan, tanpa mengejar namun proaktif.”  </p>
                                    <p className="__text-testimoni"><b>- Astini</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Saya bisa lebih jernih dalam berpikir sehingga tau cara menentukan apa yang harus saya lakukan, sehingga tidak lagi bucin dan berfokus mengharap hanya pada satu laki-laki, melainkan berfokus untuk memprioritaskan tujuan pribadi, mengembangkan passion diri dan lebih bisa menghargai diri sendiri, karena saya mencintai diri saya sendiri melebihi siapa pun.” </p>
                                    <p className="__text-testimoni"><b>- Anes</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Buat yang single kita bisa deh tuh terapin langkah-langkahnya buat tanam benih ke dia. Lebih proaktif lagi misalkan. Tapi trainingnya bukan buat yang single aja, tapi yang udah double pun bisa untuk menjaga hubungan tetap hangat. 🥰”  </p>
                                    <p className="__text-testimoni"><b>- Dianas</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“HARUS IKUT! Membuka wawasan & kesadaran banget untuk modal kita membangun hubungan romansa, jadi ga sabar buat praktek.”  </p>
                                    <p className="__text-testimoni"><b>-  Indah Riyanti</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Hanya buang2 waktu dan perasaan jika kalian masih ragu untuk mengikuti Attraction Mastery ini. Karna apa yang disampaikan semua 99% kita pasti mengalaminya dan kita akan dapat trik2 untuk bisa menyikapinya” </p>
                                    <p className="__text-testimoni"><b>- Fitri</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">Dijamin dengan ikut training attraction mastery ini, gak akan kehabisan ide buat menarik perhatian si gebetan untuk terus balik lagi dan jadi diriku semenarik mungkin di hati mereka, tentu dengan menjadi diriku yg se-unpredictable mungkin :)” </p>
                                    <p className="__text-testimoni"><b>- Imas Rahadita</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5">
                        <h2 className="__text-headline text-white text-center  __margin-bottom-5-wl">
                        Dan masih banyak lagi ratusan testimoni jujur lainnya yang tidak akan cukup ditampilkan disini. <br/>Tunggu apalagi?
                        </h2>
                        <Col lg={12}>
                                    <div className="d-flex justify-content-center wl-am-fr mt-5">
                                        
                                            <Button variant="primary" size="lg" onClick={() => scrollTo('#buy-bottom')}>
                                                YA SAYA MAU DAFTAR
                                            </Button>{' '}
                                        
                                    
                                    </div>
                                   
                                        <p className="text-center pt-3 text-white __text-link" onClick={() => scrollTo('#buy-bottom')}><u>( klik disini untuk instan akses )</u></p>
                                   
                                    </Col>
                    </Row>
                    
                </Container>
            </Container>

        </>
    )
}